<template>

	<PopupLayer>

		<template
			v-slot:body
		>
			<div class="width-400 ma-auto radius-20  color-white text-center">

				<div class="justify-space-between under-line pa-20 bg-mafia-blue">
					<h3 class="color-white">NFT SWAP</h3>
					<v-icon @click="$emit('cancel')" class="color-white">mdi-close-circle</v-icon>
				</div>

				<div class="flex-1 flex-column justify-center pa-30 bg-black">
					<NftSwapResult
						v-if="item_result.swap_nft_number"
						:user="user"
						:result_info="item_result"
					></NftSwapResult>

					<div
						v-else
						class="full-height flex-column justify-space-between"
					>
						<div class="full-height overflow-y-auto ">
							<div class="mt-80 ">
								<div
									class="square-100 ma-auto radius-10"
								>
									<img
										:src="item.cartl_nft_img_url"
										@error="$bus.$emit('onErrorImage', $event)"
										class="object-cover"
									/>
								</div>
								<div>{{ item.cartl_nft_name }}</div>
							</div>

							<div class="text-center mt-30 position-relative prl-20">
								<hr class="under-line mt-10 line-white-03" />
								<div class="position-absolute-full text-center" style="top: -9px; z-index: 99;">
									<div class="bg-black ma-auto " style="width: 40px">
										<img :src="require('@/assets/image/icon-swap-down.svg')"/>
									</div>
								</div>
							</div>

							<div class="mt-30">
								<div
									class="square-100 position-relative ma-auto radius-10"
								>
									<img
										:src="require('@/assets/image/img-random-swap.jpg')"
										@error="$bus.$emit('onErrorImage', $event)"
										class="object-cover"
									/>
									<div
										class="position-absolute-full flex-column justify-center items-center bg-black "
										style="opacity: 0.5"
									>
										<img
											:src="require('@/assets/image/icon-question.svg')"
											class="width-50"
										>
									</div>
								</div>
								<div class="mt-10 color-white size-px-16 text-center">{{ $language.add.title_swap }}</div>
							</div>

							<div class=" pa-20 color-white size-px-13">
								<h6 class="size-px-16">{{ $language.add.title_swap_notice }} <!-- 유의사항 --></h6>
								<div class="mt-20">
									{{ $language.add.txt_swap_notice1 }} <!-- 스왑한 NFT는 턱성상 취소가 불가능합니다. -->
									<br/>
									{{ $language.add.txt_swap_notice2 }} <!-- 따라서 신중히 확인한 뒤 스왑해주시기 바랍니다. -->
									<br/><br/>
									{{ $language.add.txt_swap_notice3 }} <!-- 스왑한 NFT는 랜덤으로 지급처리됩니다. -->
								</div>
								<div
									class="mt-30 cursor-pointer"
									@click="is_agree = !is_agree"
								><v-icon v-if="is_agree" class="color-white">mdi-checkbox-marked-circle</v-icon><v-icon v-else class="color-white">mdi-checkbox-blank-circle</v-icon> {{ $language.add.txt_swap_notice4 }} <!-- 상기 상품 유의사항에 동의합니다.(필수). --></div>
							</div>
						</div>

						<div
							class="mt-auto btn_area pa-20"
						>
							<button
								class="btn-inline btn-primary radius-20"
								:disabled="!is_agree"

								@click="onPin"
							>SWAP</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import NftSwapResult from "@/view/Nft/NftSwapResult";
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'NftSwapItem'
	,
	components: {PopupLayer, NftSwapResult},
	props: ['user', 'item_info']
	, data: function(){
		return {

			program: {
				name: 'nft swap'
				, type: 'popup'
				, title: 'NFT SWAP'
			}
			, cartl_nft_number: this.item_info.cartl_nft_number
			, item: {
				cartl_nft_name: '' // 카르텔NFT명", ->not null
				, cartl_nft_img_url: '' // 카르텔NFT이미지URL", ->not null
				, cartl_nft_kind_name: '' // 카르텔NFT종류명", ->not null
				, cartl_nft_kind_img_url: '' // 카르텔NFT종류이미지URL", ->not null
				, swap_nft_representation_name: '' // 스왑NFT대표명", ->not null
			}
			, item_swap: {

			}
			, is_agree: false
			, item_result: {
				swap_nft_img_url: ''
			}
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_nft_swap
					, data: {
						member_number: this.user.member_number
						, cartl_nft_number: this.cartl_nft_number
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
				}else{
					throw result.message
				}

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postSwap: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_nft_swap
					, data: {
						member_number: this.user.member_number
						, cartl_nft_number: this.cartl_nft_number
						, pinnumber: this.pinnumber
					}
					, type: true
				})

				if(result.success){
					this.item_result = result.data
				}else{
					throw result.message
				}

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPin: function(){
			this.$bus.$emit('onPin', 'check')
			this.$bus.$off('pinCallback')
			this.$bus.$on('pinCallback', (type, pinnumber) => {
				if(type == 'check'){
					this.pinnumber = pinnumber
					this.postSwap()
				}
			})
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>