<template>
	<div class="mt-30 text-left swap pb-150">
		<div class="under-line-bbb pb-10 flex-row align-center">
			<h3 class="flex-1 size-px-20">NFT SWAP</h3>
			<div>

				<button
					class="btn-inline btn-primary radius-20 pa-10-30 mr-10"
					@click="toNft"
				><img :src="require('@/assets/image/icon-swap.svg')" class="mr-10">NFT 컬렉션</button>
			</div>

		</div>
		<div class="pt-30 ">
			<div
				v-if="list_nft.length > 0"
			>
				<ul
					class="mt-20"
				>
					<li
						v-for="(item, index) in list_nft"
						:key="'item_' + index"
						@click="item_swap = item"
						class="inline-block box-nft-card position-relative mb-20 cursor-pointer"
					>
						<div
							class="width-100 radius-20"
							:class="{ on: item_swap.cartl_nft_number == item.cartl_nft_number}"
						>
							<img :src="item.cartl_nft_img_url" class="object-cover "/>
						</div>

						<img
							v-if="item_swap.cartl_nft_number == item.cartl_nft_number"
							:src="require('@/assets/image/icon-check.svg')"
							class="position-absolute"
							style="left: 10px; top: 10px"
						/>

					</li>
				</ul>
			</div>
			<Empty
				v-else
				class="color-white"
			></Empty>
		</div>

		<div
			v-if="list_nft.length > 0"
			class="text-center mt-30 top-line pt-50"
		>
			<button
				class="btn-inline btn-primary radius-20"
				:disbled="is_disable"
				@click="onItem"
			>{{ $language.common.next }} <!-- 다음 --></button>
		</div>

		<NftSwapItem
			v-if="is_on_item"
			:user="user"
			:item_info="item_swap"

			@cancel="offItem"
		></NftSwapItem>
	</div>
</template>

<script>

import Empty from "@/view/Layout/Empty";
import NftSwapItem from "@/view/Nft/NftSwapItem";
export default{
	name: 'NftSwap'
	, props: ['Axios', 'sample', 'user']
	, components: {NftSwapItem, Empty}
	, data: function(){
		return {
			program: {
				name: 'nft swap'
				, type: 'popup'
				, title: 'NFT SWAP'
				, from: 'mafia018'
			}
			, items: {
				holding_cartl_nft_list: []
				, holding_external_nft_list: []
			}
			, item_swap: {
				cartl_nft_number: ''
			}
			, is_on_item: false
		}
	}
	,computed: {
		is_disable: function(){
			let t = true
			if(this.item_swap.cartl_nft_number){
				t = false
			}
			return t
		}
		, list_nft: function(){
			let t = []
			this.items.holding_cartl_nft_list.filter( (item) => {
				t.push((item))
			})
			this.items.holding_external_nft_list.filter( (item) => {
				t.push((item))
			})
			return t
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_nft_swap_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.items = result.data
				}else{
					throw result.message
				}

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onItem: function(){
			this.is_on_item = true
		}
		, offItem: function(){
			this.is_on_item = false
			this.item_swap = {}
		}
		, toNft: function(){
			this.$bus.$emit('to', { name: 'nft'})
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}

</script>
<style>
.card_info { float: none; text-align: right;}

.swap .on {
	border: 5px solid var(--blue02);
	box-sizing: border-box;
}
</style>