<template>
	<div class="color-white full-height flex-column justify-space-between">
		<div class="full-height">
			<div class="mt-50 text-center size-px-20 font-weight-700">NFT SWAP</div>
			<div class="mt-30 ma-auto square-260 radius-20">
				<img :src="result_info.swap_nft_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" />
			</div>
		</div>

		<div class="mt-auto size-px-14 text-center pa-20 bg-blue-02 radius-t-20">
			<div class="size-px-16 font-weight-700">{{ $language.add.title_swap_result }} <!-- SWAP 완료 --></div>
			<div class="mt-20 color-gray">
				{{ $language.add.txt_swap_result }} <!-- NFT 컬렉션에서 스왑하신 NFT CARD를 학인할 수 있습니다.-->
			</div>
			<div class="btn_area mt-30">
				<button
					class="btn-inline btn-primary radius-20"

					@click="toList"
				>확인</button>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'NftSwapResult'
	, props: ['user', 'result_info']
	, data: function(){
		return {
			item: {

			}
		}
	}
	, methods: {
		toList: function(){
			this.$bus.$emit('to', { name: 'nft'})
		}
	}
}
</script>